import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Box, Button, Stack, Typography } from "@mui/material";
import dollarIcon from "../../../utils/images/coin-svgrepo-com.svg";
import totalUSerIcon from "../../../utils/images/total-user.svg";
import activeUserIcon from "../../../utils/images/active-user.svg";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import TableHeader from "../../../components/TableHeader/TableHeader";
import Api from "../../../services/axios";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewFund from "../../../components/viewFunds";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import AddOfflineTransaction from "./addOfflineTrans";

export default function DashboardComp() {
  const [state, setState] = useState({
    getUserCount: "0",
    getTotalUserFund: "0",
    getTotalRewardAmount: "0",
    getTotalReferralAmount: "0",
  });
  const [lists, setList] = useState([]);
  const [viewFund, setViewFund] = useState(false);
  const [selectedTrans, setSelectedTrans] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [adminFund, setAdminFund] = useState("");
  const [tranAmount, setTransAmount] = useState("");
  const [offlineTrans, setOfflineTrans] = useState(false);

  setTimeout(() => {
    setRefetch((prev) => !prev);
  }, 60000);

  useEffect(() => {
    Api.post("/admin/get-all-withdrawal-deposit-requests-by-status", {
      transaction_completed: false,
    })
      .then((res) => {
        console.log("list of withdraw and deposit ", res);
        setList(res.data.data.data);
      })
      .catch((err) => {
        console.log("list of withdraw and depsit err", err);
      });

    Api.get("/admin/get-total-adminFund", {
      transaction_completed: false,
    })
      .then((res) => {
        console.log("Admin Total FUnd ==  ", res);
        setAdminFund(res.data.data.sum);
      })
      .catch((err) => {
        console.log("list of withdraw and depsit err", err);
      });
  }, [refetch]);

  const handleDepositComplete = (id) => {
    Api.post("/admin/complete-withdrawDeposit-request", {
      transaction_number: id,
    })
      .then((res) => {
        // console.log("deposit complete == ", res);
        toast.success(res.data.message);
        setRefetch((prev) => !prev);
      })
      .catch((er) => {
        console.log("deposit error == ", er);
        toast.error(er.response.data.error);
      });
  };

  const handleDepositReject = (id) => {
    Api.post("/admin/update-false-withdrawDeposit-request", {
      transaction_number: id,
    })
      .then((res) => {
        // console.log("reject deposit == ", res);
        toast.success(res.data.message);
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        console.log("reject deposit error == ", err);
      });
  };
  const columns = [
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <span>
          {value.value.slice(0, 10)} -- {new Date(value.value).getHours()}
          {":"}
          {new Date(value.value).getMinutes()}
        </span>
      ),
    },
    {
      field: "transaction_type",
      headerName: "Type",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "transaction_number",
      headerName: "Transaction number",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "utr_number",
      headerName: "UTR number",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "transaction_amount",
      headerName: "Transaction Amount",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Box>
          {value.value.type == "deposit" ? (
            <Stack direction="row" spacing={1} mt={1}>
              <DoneIcon
                sx={{
                  backgroundColor: "lightgreen",
                  color: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleDepositComplete(value.value.tranNo)}
              />
              <CloseIcon
                sx={{
                  backgroundColor: "red",
                  color: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleDepositReject(value.value.tranNo)}
              />
            </Stack>
          ) : (
            <RemoveRedEyeIcon
              onClick={() =>
                viewPendingRequest(value.value.tranNo, value.value.tranAmount)
              }
              sx={{
                cursor: "pointer",
              }}
            />
          )}
        </Box>
      ),
    },
  ];

  const viewPendingRequest = (number, amount) => {
    setViewFund(true);
    setTransAmount(amount);
    setSelectedTrans(number);
  };

  useEffect(() => {
    Api.get("/admin/get-total-userFund")
      .then((res) => {
        // console.log("admin get total userFund", res);
        setState((previous) => {
          return { ...previous, getTotalUserFund: res.data.data.sum };
        });
      })
      .catch((err) => {
        console.log("admin get total userFund", err);
      });

    Api.get("/admin/get-total-rewardAmount")
      .then((res) => {
        // console.log("admin get total rewardAmount", res);
        setState((previous) => {
          return { ...previous, getTotalRewardAmount: res.data.data.sum };
        });
      })
      .catch((err) => {
        console.log("admin get total rewardAmount", err);
      });

    Api.get("/admin/get-total-referralAmount")
      .then((res) => {
        // console.log("admin get total referralAmount", res);
        setState((previous) => {
          return { ...previous, getTotalReferralAmount: res.data.data.sum };
        });
      })
      .catch((err) => {
        console.log("admin get total referralAmount", err);
      });
    Api.get("/admin/get-user-count")
      .then((res) => {
        // console.log("admin get user count", res);
        setState((previous) => {
          return { ...previous, getUserCount: res.data.data.count };
        });
      })
      .catch((err) => {
        console.log("admin get user error", err);
      });
  }, []);
  console.log("state === ", state);
  return (
    <Box>
      <Stack direction="row" className={styles.headerContainer}>
        <Typography className={styles.tableHead}>Dashboard</Typography>
        <Button
          className={styles.btnClass}
          onClick={() => setOfflineTrans(true)}
          variant="contained"
          color="primary">
          Add Offline Transaction
        </Button>
      </Stack>
      <Stack className={styles.cardCont}>
        <Stack className={styles.item}>
          <img src={totalUSerIcon} alt="dollar" className={styles.icon} />
          <Box ml={1}>
            <Typography className={styles.itemHead}>All User Count</Typography>
            <Typography className={styles.itemCount}>
              {state.getUserCount}
            </Typography>
          </Box>
        </Stack>

        <Stack className={styles.item}>
          <img src={dollarIcon} alt="dollar" className={styles.icon} />
          <Box ml={1}>
            <Typography className={styles.itemHead}>
              Total Referral Amount
            </Typography>
            <Typography className={styles.itemCount}>
              {state.getTotalReferralAmount
                ? state.getTotalReferralAmount
                : "0"}
            </Typography>
          </Box>
        </Stack>

        <Stack className={styles.item}>
          <img src={activeUserIcon} alt="dollar" className={styles.icon} />
          <Box ml={1}>
            <Typography className={styles.itemHead}>Total User Fund</Typography>
            <Typography className={styles.itemCount}>
              {state.getTotalUserFund ? state.getTotalUserFund : "0"}
            </Typography>
          </Box>
        </Stack>

        <Stack className={styles.item}>
          <img src={dollarIcon} alt="dollar" className={styles.icon} />
          <Box ml={1}>
            <Typography className={styles.itemHead}>
              Total Reward Amount
            </Typography>
            <Typography className={styles.itemCount}>
              {state.getTotalRewardAmount ? state.getTotalRewardAmount : "0"}
            </Typography>
          </Box>
        </Stack>

        <Stack className={styles.item}>
          <img src={dollarIcon} alt="dollar" className={styles.icon} />
          <Box ml={1}>
            <Typography className={styles.itemHead}>Admin Coin</Typography>
            <Typography className={styles.itemCount}>
              {adminFund ? adminFund : "0"}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Pending Withdraw Transaction"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns?.filter((ele) => ele.field !== "utr_number")}
            initialRows={
              lists
                ? lists
                    .filter((ele) => ele.transaction_type === "withdraw")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                        actions: {
                          tranNo: ele?.transaction_number,
                          type: ele?.transaction_type,
                          tranAmount: ele?.transaction_amount,
                        },
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>

      <Box className={styles.dataGridComp}>
        <TableHeader heading={"Pending Deposit Transaction"} />
        <Box p={1} mt={1}>
          <DataGridComp
            columns={columns.filter(
              (ele) => ele.field !== "transaction_number"
            )}
            initialRows={
              lists
                ? lists
                    .filter((ele) => ele.transaction_type !== "withdraw")
                    .map((ele, idx) => {
                      return {
                        id: idx,
                        ...ele,
                        actions: {
                          tranNo: ele?.transaction_number,
                          type: ele?.transaction_type,
                          tranAmount: ele?.transaction_amount,
                        },
                      };
                    })
                : []
            }
          />
        </Box>
      </Box>

      {viewFund && (
        <ViewFund
          openPop={viewFund}
          handleClose={() => setViewFund(false)}
          id={selectedTrans}
          transAmount={tranAmount}
          refetch={() => {
            setRefetch((prev) => !prev);
            setViewFund(false);
          }}
        />
      )}
      {offlineTrans && (
        <AddOfflineTransaction
          openProp={offlineTrans}
          handleClose={() => setOfflineTrans(false)}
        />
      )}
    </Box>
  );
}
