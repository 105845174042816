import {
  AppBar,
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import FaceIcon from "@mui/icons-material/Face";

export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const user = localStorage.getItem("token");

  return (
    <AppBar component="nav" className={styles.nav}>
      <Toolbar>
        <Typography color={"primary"} className={styles.logo}>
        Amar Games
        </Typography>
        {user ? (
          <Stack className={styles.profileCont} onClick={handleClick}>
            <Avatar className={styles.avatar}>A</Avatar>
            {/* <Box>
              <Typography className={styles.name}>AmanGamer@123</Typography>
              <Typography></Typography>
            </Box> */}

            <KeyboardArrowDownIcon sx={{ color: "grey" }} />
          </Stack>
        ) : (
          <Box>
            <Button
              variant="outlined"
              className={styles.loginBtn}
              onClick={() => navigate("/login")}>
              Log In
            </Button>
            <Button
              variant="standard"
              className={styles.registerBtn}
              onClick={() => navigate("/registration")}>
              Register
            </Button>
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          <MenuItem
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
            className={styles.menuitem}>
            <FaceIcon className={styles.menuIco} /> Profile
          </MenuItem>
          <MenuItem onClick={handleLogout} className={styles.menuitem}>
            <LogoutIcon className={styles.menuIco} /> Log Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
